import { navigateToUrl } from 'single-spa'
import {
  authManager,
  featureFlagsInformer,
  FeatureFlagsModel,
  hideLoading,
  showLoading,
  subscriptionInformer,
  SubscriptionModel,
} from '@ubits/library-core'
import {
  getCompanyPage,
  getHomepage,
  hideFooter,
  isAdminSetup,
  isCustomCourseCreator,
  logout,
  updateAppcuesPage,
} from '../services'
import { routerList } from './router.list'
import { RouterModel } from './router.model'

export async function routerService() {
  await onRouterHashchange()
  sessionValidation()

  window.addEventListener('popstate', () => {
    onRouterHashchange().then()
  })
}

function getRouterItem(url: string): RouterModel {
  const origin = window.location.origin
  url = url.replace(origin, '')
  if (!url || url === '/') return null

  const routerKey: string = Object.keys(routerList).find((key: string) => {
    const routerItemUrl = routerList[key].url
    let isValid: boolean = url.includes(routerItemUrl)

    if (url.length > routerItemUrl.length && isValid)
      isValid = ['?', '/'].includes(url.replace(routerItemUrl, '')[0])

    return isValid
  })

  return routerKey ? routerList[routerKey] : null
}

function updateDocumentTitle() {
  const routerItem: RouterModel = getRouterItem(window.location.href)
  if (!routerItem) return

  const routerTitle = routerItem?.title || ''
  document.title = `Ubits ${routerTitle && '|'} ${routerTitle}`.trim()
}

async function onRouterHashchange() {
  handleToggleLoading()

  await reloadHandlerLogout()
  await reloadHandlerCourseCreator()
  await reloadHandlerAdmin()
  await reloadHandlerLMS()
  await reloadHandlerInitial()

  hideFooter()
  updateDocumentTitle()
  updateAppcuesPage()
}

async function redirectToHomepage() {
  const isAdmin: boolean = await isAdminSetup()
  const url = isAdmin
    ? await getHomepage()
    : [await getCompanyPage(), await getHomepage()].find(url => getRouterItem(url))
  navigateToUrl(url)
}

async function reloadHandlerCourseCreator() {
  const isCourseCreator: boolean = await isCustomCourseCreator()
  if (!isCourseCreator) return

  const regexUlmsUrl = new RegExp(/^\/ulms\/.*$/)
  const { pathname } = window.location
  const match = regexUlmsUrl.test(pathname)
  const featureFlagUlms: FeatureFlagsModel = await featureFlagsInformer.get('new_ulms')

  if (featureFlagUlms.active) {
    if (!match) navigateToUrl('/ulms/content')
  } else {
    const url = process.env['LMS_URL']
    window.location.href = `${url}/local/admincourses/index.php`
  }
}

async function reloadHandlerInitial() {
  let { href } = window.location

  if (href.includes('/#/')) {
    window.location.href = href.replace('/#/', '/')
    href = href.replace('/#/', '/')
  }

  if (!getRouterItem(href)) await redirectToHomepage()
}

async function reloadHandlerAdmin() {
  const regexAdminHRUrl = new RegExp(/^\/admin-hr\/.*$/)
  const { pathname } = window.location

  const match = regexAdminHRUrl.test(pathname)

  if ((await isAdminSetup()) && !match) navigateToUrl(await getHomepage())
}

async function reloadHandlerLMS() {
  const whitelist = ['/lms-login']
  const { pathname } = window.location

  if (whitelist.some(item => pathname.includes(item))) {
    await redirectToHomepage()
    window.top.dispatchEvent(new CustomEvent('refreshLms', { bubbles: true }))
  }
}

async function reloadHandlerLogout() {
  const whitelist = ['/logout']
  const { pathname } = window.location
  if (whitelist.includes(pathname)) {
    await logout()
  }
}

function handleToggleLoading() {
  // showLoading()
  // const blacklist = ['/lms']
  // const { pathname } = window.location
  // if (!blacklist.some(item => pathname.includes(item))) hideLoading()
}

function sessionValidation() {
  subscriptionInformer.get().then((subscription: SubscriptionModel) => {
    const { subscriptionIsActive, userId } = subscription
    if (!subscriptionIsActive && Boolean(userId)) navigateToUrl('/verifyValidity')
  })
}
