import { preferenceCompanyInformer, subscriptionInformer } from '@ubits/library-core'
import { RoleTypeEnum } from '@ubits/library-enums'
import { routerList } from '../router/router.list'

const defaultHomePage = routerList['homeLearning']
const defaultAdminPage = routerList['adminHRCompany']

export async function isCustomCourseCreator(): Promise<boolean> {
  const role: RoleTypeEnum = await subscriptionInformer.role()
  return role === RoleTypeEnum.CustomCourseCreator
}

export async function isAdminSetup(): Promise<boolean> {
  const role: RoleTypeEnum = await subscriptionInformer.role()
  return role === RoleTypeEnum.AdminSetup
}

export async function getHomepage(): Promise<string> {
  const isAdmin = await isAdminSetup()
  return isAdmin ? defaultAdminPage.url : defaultHomePage.url
}

export async function getCompanyPage(): Promise<string> {
  return preferenceCompanyInformer.getHomePage()
}
