import './assets/styles/global.style.scss'
import { authInformer, authManager } from '@ubits/library-core'
import { registerApplication, startApplication } from './register.application'
import { routerService } from './router'
import { footerTycEventListener, ubitsManagerContentScroll } from './scripts'
import {
  appcuesService,
  dataLayerService,
  internationalizationService,
  refreshLmsService,
  sessionManager,
} from './services'
import { devTools, getDevDependencies } from './utils'

async function initializeApp() {
  /* Dev Services */
  getDevDependencies()
  devTools()

  /* Auth Services */
  await authManager.initializeApp()
  const isAuthenticated: boolean = await authInformer.isAuthenticated()

  if (!isAuthenticated) return
  await sessionManager.main()
  await internationalizationService()
  await routerService()

  /* Application Services */
  registerApplication()
  startApplication()

  /* Additional services and scripts */
  appcuesService()
  dataLayerService()
  footerTycEventListener()
  ubitsManagerContentScroll()
  refreshLmsService()
}

Promise.resolve(initializeApp())

export * from './shared/index'
