import { DataLayerDirective } from './data-layer.directive'

export function dataLayerDirective(tagName: string, data: any): DataLayerDirective {
  tagName = tagName?.trim()?.toLocaleLowerCase()

  switch (tagName) {
    case 'page-viewed':
      return new DataLayerDirective({
        name: 'page-viewed',
        model: { hash: window.location.href },
      })
    case 'ubits-tab-view':
      return new DataLayerDirective({
        name: 'tab-viewed',
        model: { tabName: data['header'] },
      })
    case 'ubits-content-card':
      return new DataLayerDirective({
        name: 'card-clicked',
        model: {
          cardType: 'ubits-content-card',
          contentId: data['id'],
          contentType: data['contentTypeText'],
          contentTitle: data['title'],
        },
      })
    case 'ubits-promo-card':
      return new DataLayerDirective({
        name: 'card-clicked',
        model: {
          cardType: 'ubits-promo-card',
          contentId: data['id'],
          contentType: data['subtitle'],
          contentTitle: data['title'],
        },
      })
    case 'ubits-talent-card':
      return new DataLayerDirective({
        name: 'card-clicked',
        model: {
          cardType: 'ubits-talent-card',
          contentId: data['id'],
          contentType: data['label'],
          contentTitle: data['titleCard'],
        },
      })
    case 'modal-buy-license':
      return new DataLayerDirective({
        name: 'buy-license',
        model: data,
      })
    case 'modal-renew-license':
      return new DataLayerDirective({
        name: 'renew-license',
        model: data,
      })
    case 'estudiante-learning-modal-responder-axs':
      return new DataLayerDirective({
        name: 'estudiante-learning-modal-responder-axs',
        model: data,
      })
    case 'estudiante-learning-snackbar-responder-axs':
      return new DataLayerDirective({
        name: 'estudiante-learning-snackbar-responder-axs',
        model: data,
      })
    case 'header-search':
    case 'catalog-search-with-result':
    case 'catalog-search-without-result':
    case 'catalog-content-selected':
    case 'jelper-ui-opened':
    case 'jelper-ui-closed':
    case 'jelper-message-sent':
    case 'jelper-message-received':
    case 'jelper-message-selected':
    default:
      return new DataLayerDirective({
        name: tagName,
        model: data,
      })
  }
}
