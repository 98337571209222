import { getMountedApps, unregisterApplication } from 'single-spa'
import { authManager, showLoading } from '@ubits/library-core'

export async function logout() {
  showLoading({ showHeader: false })
  await unmountApplications()
  await authManager.logout()
}

async function unmountApplications() {
  const apps = getMountedApps()
  for (const app of apps) {
    await unregisterApplication(app)
  }
}
