import { registerApplication as registerApplicationSPA, start } from 'single-spa'
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout'
import indexLayout from './layouts/index.layout.html'
import { recordApplication } from './record.application'

export function registerApplication() {
  const routes = constructRoutes(configLayout(indexLayout))
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name)
    },
  })

  const layoutEngine = constructLayoutEngine({ routes, applications })
  applications.forEach(registerApplicationSPA)
  layoutEngine.activate()
  recordApplication.forEach(config => registerApplicationSPA(config))
}

const configLayout = (layout: string) => {
  const origin = window.location.origin
  const logoLight = origin + '/assets/svgs/ubits-logo.light.svg'
  const logoDark = origin + '/assets/svgs/ubits-logo.dark.svg'

  return layout.replace('_#logoLight#_', logoLight).replace('_#logoDark#_', logoDark)
}

export function startApplication() {
  start()
}
