import { RouterRecordModel } from './router.model'

export const routerList: RouterRecordModel = {
  adminHRCertificates: { url: '/admin-hr/local/certificates_request' },
  adminHRCustomization: { url: '/admin-hr/customization' },
  adminHRCompany: { url: '/admin-hr' },
  adminHRHelpCenter: { url: '/admin-hr/help-center' },
  adminHRHome: { title: 'AdminHR', url: '/admin-hr/home' },
  adminHRMetrics: { title: 'AdminHR', url: '/admin-hr/metrics' },
  adminHRUsers: { title: 'AdminHR | Users', url: '/admin-hr/users' },
  adminHR: { title: 'AdminHR', url: '/admin-hr' },
  catalog: { title: 'Catalogo', url: '/catalog' },
  search: { title: 'Catalogo', url: '/search' },
  contentAuthoring: { title: 'Content Authoring', url: '/ulms' },
  homeLearning: { title: 'Learning', url: '/home/learning' },
  homePlaylist: { title: 'Playlist', url: '/home/learning/playlist' },
  homeProgram: { title: 'Programa', url: '/home/program' },
  homeCertificate: { title: 'Certificado', url: '/home/certificate' },
  homeCompetence: { title: 'Competencia', url: '/home/competence' },
  homeStudyZone: { title: 'Zona de estudio', url: '/home/study-zone' },
  homeUbitsMax: { title: 'Ubits Max', url: '/home/ubits-max' },
  lmsCourse: { title: 'Curso', url: '/lms/course' },
  lmsStudentMetrics: { title: 'Métricas', url: '/lms/local/studentmetrics' },
  lmsCreator: { title: 'Creación de contenido', url: '/creator' },
  lmsLearner: { title: 'Cursos de mi empresa', url: '/learner' },
  lmsUniversity: { title: 'Cursos de mi empresa', url: '/university' },
  logout: { url: '/logout' },
  profile: { title: 'Perfil', url: '/profile' },
  assessments360: { title: 'Talent 360', url: '/talent/360' },
  objectives: { title: 'Objetivos', url: '/talent/goals' },
  organizationChart: { title: 'Organigrama', url: '/organization-chart' },
  surveys: { title: 'Encuestas', url: '/surveys' },
  portal: { title: 'Portal Colaborador', url: '/portal' },
  history: { title: 'Mi historial', url: '/learn/contents/history' },
}
