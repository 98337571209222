import packageCore from '@ubits/library-core/package.json'
import packageEnums from '@ubits/library-enums/package.json'
import packageEventsTrack from '@ubits/library-events-track/package.json'
import packageInternationalization from '@ubits/library-internationalization/package.json'

export function getDevDependencies() {
  const name = 'Manager'
  window[`devDependencies${name}`] = {
    '@ubits/library-core': packageCore.version,
    '@ubits/library-enums': packageEnums.version,
    '@ubits/library-events-track': packageEventsTrack.version,
    '@ubits/library-internationalization': packageInternationalization.version,
  }

  window['allDevDependencies'] = () => {
    return Object.keys(window)
      .filter(key => key.includes('devDependencies'))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: window[key],
        })
      }, {})
  }

  window['environment'] = process.env.ENV
}
