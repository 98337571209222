import { subscriptionInformer } from '@ubits/library-core'

export function appcuesService() {
  window['AppcuesSettings'] = {
    enableURLDetection: true,
  }

  const script = document.createElement('script')
  script.src = 'https://fast.appcues.com/84494.js'
  script.onload = () => loadAppcues().then()
  document.head.appendChild(script)
}

export function updateAppcuesPage() {
  const appcues = window['Appcues']
  if (appcues?.page) appcues?.page()
}

async function loadAppcues() {
  const appcues = window['Appcues']
  if (!appcues) return

  const info = await subscriptionInformer.get()
  const userId = parseInt(info['userId'])
  appcues.identify(userId, {
    'created_at': info.subscriptionStartDate,
    'role': info.role,
    'first_name': info.firstName,
    'company_name': info.companyName,
    'email': info.email,
  })

  updateAppcuesPage()
}
