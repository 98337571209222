export class StoreTemporarily {
  private cache = new Map<string, any>()
  private promises: {
    key: string
    callback: () => Promise<any>
    resolve: (value?: any) => void
  }[] = []
  private resolving = false
  private autoRemove: number = 3000

  async load(key: string, callback: () => Promise<any>): Promise<any> {
    if (this.cache.has(key)) return this.getData(key)

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      this.promises.push({ key, callback, resolve })

      if (!this.resolving) {
        this.resolving = true
        await this.resolvePromiseQueue()
        this.resolving = false
      }
    })
  }

  private async resolvePromiseQueue() {
    while (this.promises.length > 0) {
      const { key, callback, resolve } = this.promises.pop()

      try {
        let response

        if (this.cache.has(key)) {
          response = this.getData(key)
        } else {
          response = await callback()
          this.cache.set(key, response)
        }

        resolve(response)
      } catch (error) {
        resolve(null)
      }
    }
  }

  private getData(key: string) {
    setTimeout(() => {
      this.cache.delete(key)
    }, this.autoRemove)

    return this.cache.get(key)
  }
}
