import { authInformer, subscriptionInformer, SubscriptionModel } from '@ubits/library-core'
import { dataLayer } from './data-layer.track'

type DataLayerDirectiveModelType = Record<string, any>

export class DataLayerDirective {
  private name: string = ''
  private model: DataLayerDirectiveModelType = {}

  private userId: string = ''
  private companyId: string = ''
  private countryId: string = ''
  private screenName: string = ''
  private timeStamp: number = 0

  constructor({ name, model }: { name: string; model: DataLayerDirectiveModelType }) {
    this.name = name
    this.model = model
  }

  async dispatch() {
    const dataLayer = this.getDataLayer()
    if (!dataLayer) return
    const value: any = await this.getValue()
    if (!value.userId) return

    const detail = {
      event: value.name,
      eventModel: value,
    }

    dataLayer.emit(detail)
  }

  async getValue() {
    await this.loadDefaultData()

    return {
      name: this.name,
      ...this.model,
      userId: this.userId,
      companyId: this.companyId,
      countryId: this.countryId,
      screenName: this.screenName,
      timeStamp: this.timeStamp,
    }
  }

  private async loadDefaultData() {
    const subscription: SubscriptionModel = await subscriptionInformer.get()

    const user = await authInformer.user()
    const countryId = user.userMetadata.country

    this.userId = subscription.userName
    this.companyId = subscription.companyId
    this.countryId = countryId
    this.screenName = document.title
    this.timeStamp = Date.now()
  }

  private getDataLayer() {
    return dataLayer
  }
}
