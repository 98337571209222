import { dataLayerDirective } from './data-layer.provider'

export function dataLayerService() {
  popstateEvent()
  dispatchCurrentPageEvent()
  subscribeGlobalEvent()
}

function dispatchDataLayer(internalEventName: string, data: any) {
  const dataLayer = dataLayerDirective(internalEventName, data)
  dataLayer?.dispatch().then()
}

function dispatchCurrentPageEvent() {
  const internalEventName = 'page-viewed'
  const data = { 'hash': location }
  dispatchDataLayer(internalEventName, data)
}

function popstateEvent() {
  let location: string = null

  window.addEventListener('popstate', () => {
    const currentLocation: string = window.location.href
    if (location === currentLocation) return
    location = currentLocation
    dispatchCurrentPageEvent()
  })
}

function subscribeGlobalEvent() {
  document.addEventListener('ubitsDataLayer', (event: CustomEvent) => {
    const detail: { tagName?: string; componentKey?: string; data: any } = event.detail
    const tagName = detail?.tagName || detail?.componentKey
    const data = detail?.data || {}
    if (tagName) dispatchDataLayer(tagName, data)
  })
}
