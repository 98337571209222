import {
  authInformer,
  authManager,
  preferenceCompanyManager,
  preferenceUserManager,
  subscriptionInformer,
  subscriptionManager,
  ubitsDispatchEvent,
  userInformer,
  userManager,
} from '@ubits/library-core'
import { logout } from './logout.service'

class SessionManager {
  private anticipatedTime = 900000 // 15 minutes

  async main(): Promise<void> {
    await this.loadData()
    await this.registerEvents()
  }

  async isSessionActive(): Promise<boolean> {
    const tokenExp: number = await authInformer.accessTokenExpirationTimestamp()
    const currentDate: number = new Date().getTime()
    // const currentDate: number = tokenExp + 100
    if (currentDate >= tokenExp) return false

    await authManager.checkSession()
    const isAuthenticated: boolean = await authInformer.isAuthenticated()
    return isAuthenticated
  }

  private async onUserLoggedIntoSystem() {
    const blacklist = ['/logout']
    const { pathname } = location

    if (blacklist.includes(pathname)) {
      return
    }
    const isSessionActive: boolean = await this.isSessionActive()
    if (!isSessionActive) await logout()
  }

  private async registerEvents() {
    await this.refreshTokenMessageTimer()
    window.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'hidden') return
      await this.onUserLoggedIntoSystem()
    })

    /*window.addEventListener('focus', async () => await this.onUserLoggedIntoSystem())*/
    // window.addEventListener('blur', async () => await this.onUserLoggedIntoSystem())
  }

  private async anticipatedExp(): Promise<number> {
    const exp: number = await authInformer.accessTokenExpirationTimestamp()
    const anticipatedTime = this.anticipatedTime / 1000
    return Promise.resolve(new Date(new Date(exp).setSeconds(-anticipatedTime)).getTime())
  }

  private async dispatchUpdateSessionMessage() {
    if (await this.isSessionActive()) {
      const currentDate: number = new Date().getTime()
      const anticipatedExp: number = await this.anticipatedExp()

      if (currentDate >= anticipatedExp) {
        ubitsDispatchEvent(new CustomEvent('updateSessionMessage', { bubbles: true }))
      }
    } else {
      await logout()
    }
  }

  private async refreshTokenMessageTimer() {
    const interval: number = 300000 // 5 minutes
    setInterval(async () => await this.dispatchUpdateSessionMessage(), interval)
  }

  private async loadData(): Promise<void> {
    await userManager.loadData()

    const user = await userInformer.get()
    if (!Boolean(user)) {
      await logout()
      return
    }

    await subscriptionManager.loadData()
    const userId: string = await subscriptionInformer.userId()

    if (Boolean(userId)) {
      await preferenceUserManager.loadData()
      await preferenceCompanyManager.loadData()
    } else {
      await logout()
    }
  }
}

export const sessionManager = new SessionManager()
